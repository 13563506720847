import React from 'react';

import Header from "../components/Header";
import Footer from "../components/Footer";

import './../theme/comp/giftcards.scss';

const Contact: React.FC = () => {

  const mapImage = process.env.PUBLIC_URL + '/images/map.png';

  return (
    <main>
      <div className="header-gap"></div>
      <Header />

      <div className="margin-bottom-96">
        <div className="grid text-center">
          <h1 className="headline-text">Contact Trendzone</h1><br />
          <h2 className="description-text">We’re Here for You!</h2><br />
          <div className="flex-std-res">
            <img className='map-image' src={mapImage} alt='map-image' />
            <div>
              <h2 className="description-text">At Trendzone Barbershop, your satisfaction is our priority. Whether you have a question about our services, need help booking an appointment, or simply want to share your feedback, we’re just a message away!</h2>
              <p className='margin-top'><a href="tel:+16043853000">(604) 385-3000</a></p>
              <p><a href="mailto:info@trendzonebarbershop.com">info@trendzonebarbershop.com</a></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Contact;